<template>
  <form @submit.prevent="$emit('nextstep')" class="w-full">
    <h1>Procediamo. Chi gestisce il tuo negozio?</h1>
    <div class="grid grid-cols-6 gap-4 mt-8">
      <input
        type="text"
        class="col-span-2 my-2"
        placeholder="Nome"
        v-model="s_name"
      />
      <input
        type="text"
        class="col-span-4 my-2"
        placeholder="Cognome"
        v-model="s_surname"
      />
      <input
        type="text"
        class="col-span-6 my-2"
        placeholder="Email"
        v-model="s_email"
      />
      <input
        type="password"
        class="col-span-6 my-2"
        placeholder="Password"
        v-model="s_password"
      />
      <input
        type="password"
        class="col-span-6 my-2"
        placeholder="Conferma Password"
        v-model="confirmPassword"
      />
    </div>

    <div class="flex items-baseline mt-12">
      <button
        @click="$emit('prevstep')"
        class="text-secondary-500 w-full text-center"
      >
        <i class="mdi mdi-arrow-left"></i>
        Torna indietro
      </button>
      <button
        class="btn btn-cta w-full text-center"
        :disabled="$v.$invalid"
        :class="$v.$invalid ? ' cursor-not-allowed' : null"
      >
        Continua
      </button>
    </div>
  </form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
export default {
  data: () => ({
    confirmPassword: ""
  }),
  mixins: [validationMixin],
  computed: {
    ...mapState("createstore", ["name", "surname", "email", "password"]),
    s_name: {
      get() {
        return this.name;
      },
      set(v) {
        this.SET_NAME(v);
      }
    },
    s_surname: {
      get() {
        return this.surname;
      },
      set(v) {
        this.SET_SURNAME(v);
      }
    },
    s_email: {
      get() {
        return this.email;
      },
      set(v) {
        this.SET_EMAIL(v);
      }
    },
    s_password: {
      get() {
        return this.password;
      },
      set(v) {
        this.SET_PASSWORD(v);
      }
    }
  },
  validations: {
    s_name: {
      required,
      minLength: minLength(4)
    },
    s_surname: { required, minLength: minLength(4) },
    s_email: { required, email },
    s_password: {
      required,
      minLength: minLength(8)
      // valid: function(value) {
      //   const containsUppercase = /[A-Z]/.test(value);
      //   const containsLowercase = /[a-z]/.test(value);
      //   const containsNumber = /[0-9]/.test(value);
      //   const containsSpecial = /[#?!@$%^&*-]/.test(value);
      //   return (
      //     containsUppercase &&
      //     containsLowercase &&
      //     containsNumber &&
      //     containsSpecial
      //   );
      // }
    },
    confirmPassword: {
      sameAsPassword: sameAs("s_password")
    }
  },
  methods: {
    ...mapMutations("createstore", [
      "SET_NAME",
      "SET_SURNAME",
      "SET_EMAIL",
      "SET_PASSWORD"
    ])
  }
};
</script>

<style></style>
